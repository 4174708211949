import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import { colorBlack, desktopMin, tabletMax, toCamelCase } from '~/common/utils';
import uuid from 'react-uuid';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';
import {
  Button,
  ButtonProps,
  ButtonTypeEnum,
  CustomCarousel,
  SvgIcon,
  TooltipDisclaimer,
} from '~/common/components/ui-elements';
import { CallToAction } from '~/common/models';

interface IOwnerReview {
  author?: string;
  message?: string;
  date?: Date;
}

interface IOwnerReviewsProps {
  id: number;
  title?: string;
  titleDisclaimer?: string;
  reviewList?: IOwnerReview[];
  reviewCTA?: CallToAction;
  backgroundColor?: string;
  fontColor?: string;
  tileBackgroundColor?: string;
  tileFontColor?: string;
}

const breakpoints = {
  desktop: {
    breakpoint: { max: 4000, min: desktopMin },
    items: 3,
  },
  mobile: {
    breakpoint: { max: tabletMax, min: 0 },
    items: 1,
  },
};

const ExpandableRTERenderer: React.FC<{
  html: string;
  lines: number;
  fontColor?: string;
}> = ({ html, lines, fontColor }) => {
  const [truncated, setTruncated] = useState(true);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const containerStyles = window.getComputedStyle(container);
      const lineHeight = parseFloat(containerStyles.lineHeight);
      const maxHeight = lineHeight * lines;
      const contentHeight = container.scrollHeight;

      if (contentHeight > maxHeight) {
        setShouldShowButton(true);
      }
    }
  }, [html, lines]);

  const toggleTruncate = () => {
    setTruncated(!truncated);
  };

  const truncatedHTML = (
    <div
      ref={containerRef}
      className={cn(styles.Message, { [styles.ShowMore]: truncated })}
      style={
        truncated
          ? {
              WebkitLineClamp: lines,
              lineClamp: lines,
            }
          : {}
      }
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );

  return (
    <div className={styles.ExpandableRTERenderer}>
      {truncatedHTML}
      {shouldShowButton && (
        <div className={styles.ShowMoreButton}>
          <p onClick={toggleTruncate}>{`View ${
            truncated ? 'more' : 'less'
          }`}</p>
          &nbsp;
          <SvgIcon
            display={'inline-flex'}
            type={`chevron${truncated ? 'Down' : 'Up'}`}
            color={`#${fontColor || colorBlack}`}
            size="10px"
          />
        </div>
      )}
    </div>
  );
};

export const OwnerReviews = React.memo(
  ({
    id,
    title,
    titleDisclaimer,
    reviewList,
    reviewCTA,
    backgroundColor,
    fontColor,
    tileBackgroundColor,
    tileFontColor,
  }: IOwnerReviewsProps) => {
    const [isMobile, setIsMobile] = useState(
      useMediaQuery(
        { query: `(max-width: ${tabletMax}px)` },
        undefined,
        (matches) => {
          setIsMobile(matches);
        }
      )
    );
    const [expanded, setExpanded] = useState(false);
    const _reviews = reviewList && reviewList.length > 0 ? reviewList : [];
    const reviewsTotalIndex =
      _reviews.length === 0 ? -1 : Math.ceil(_reviews.length / 3) - 1;
    const reviewsIndeces = [...Array(reviewsTotalIndex + 1).keys()];
    const [expandedIndex, setExpandedIndex] = useState(
      reviewsTotalIndex > -1 ? 0 : -1
    ); // expanded starting index on mobile

    useRTETooltipPositionCorrector(isMobile);

    const onLoad = useCallback(() => {}, []);
    useEffect(() => onLoad(), [onLoad, isMobile]);

    const handleShowMore = () => {
      if (!isMobile) {
        setExpanded(!expanded);
      } else {
        setExpandedIndex(expandedIndex + 1);
      }
    };

    const showMore = () =>
      (!isMobile && !expanded && _reviews.length > 3) ||
      (isMobile && expandedIndex < reviewsTotalIndex);

    const renderReview = (review: IOwnerReview, idx: number) => {
      const _date = review.date ? new Date(review.date) : undefined;
      const _dateFormatted = _date
        ? _date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
          })
        : undefined;
      return (
        <div
          key={idx}
          className={styles.ReviewContainer}
          style={{
            ...(!isMobile ? { flexBasis: '33.33%' } : {}),
          }}
        >
          <div
            key={idx}
            className={cn(styles.Review, styles[`Review${idx % 3}`])}
            style={{
              ...(tileBackgroundColor
                ? { backgroundColor: `#${tileBackgroundColor}` }
                : {}),
              color: `#${tileFontColor || colorBlack}`,
            }}
          >
            {review.message && (
              <ExpandableRTERenderer
                html={review.message}
                lines={5}
                fontColor={tileFontColor}
              />
            )}
            <div
              className={styles.Author}
              dangerouslySetInnerHTML={{ __html: review.author as string }}
            />
            {_dateFormatted && <h6>{_dateFormatted}</h6>}
          </div>
        </div>
      );
    };

    const getSliceEnd = (idx = expandedIndex) => {
      if (!isMobile) {
        return !expanded ? 3 : _reviews.length;
      }
      const initial = (idx + 1) * 3;
      return initial > _reviews.length ? _reviews.length : initial;
    };

    const showArrows = (list: IOwnerReview[]) => {
      if (!(list.length > 0)) return false;
      return list.length > 1;
    };

    const renderCTA = (cta: CallToAction) => {
      const _style: React.CSSProperties = {
        ...(cta.ctaColor ? { color: `#${cta.ctaColor}` } : {}),
        ...(cta.ctaColor ? { borderColor: `#${cta.ctaColor}` } : {}),
      };
      const buttonProps: ButtonProps = {
        type: toCamelCase(cta.ctaButtonType) as keyof typeof ButtonTypeEnum,
        style: _style,
        buttonWidth: cta.ctaFullWidth,
        text: reviewCTA?.ctaUrl?.name,
      };
      if (cta.ctaUrl?.url === '/') {
        buttonProps.onClick = handleShowMore;
      } else {
        buttonProps.href = cta.ctaUrl?.url;
        buttonProps.target = cta.ctaUrl?.target || '_self';
      }

      return (
        <div
          className={styles.CTA}
          style={{
            justifyContent: cta.ctaButtonAlignment || 'center',
          }}
        >
          <Button {...buttonProps} buttonSize="medium" />
        </div>
      );
    };

    return (
      <div
        id={`cid-${id}`}
        className={cn('full-device-width', styles.OwnerReviews)}
        key={uuid()}
        style={{
          ...(backgroundColor
            ? { backgroundColor: `#${backgroundColor}` }
            : {}),
          color: `#${fontColor || colorBlack}`,
        }}
      >
        {reviewsTotalIndex > -1 && (
          <div className={styles.Container}>
            <div className={styles.Title}>
              <div
                className={styles.TitleText}
                dangerouslySetInnerHTML={{ __html: title as string }}
              />
              {titleDisclaimer && (
                <TooltipDisclaimer
                  triggerClassName={styles.HeaderDisclaimerTrigger}
                  disclaimer={titleDisclaimer}
                />
              )}
            </div>
            <div
              className={cn(styles.Reviews, {
                [styles.ReviewsMobile]: isMobile,
              })}
            >
              <MediaQuery minWidth={desktopMin}>
                {_reviews
                  .slice(0, getSliceEnd())
                  .map((r, idx) => renderReview(r, idx))}
              </MediaQuery>
              <MediaQuery maxWidth={tabletMax}>
                {reviewsIndeces.slice(0, expandedIndex + 1).map((_, i) => {
                  const arr = _reviews.slice(i * 3, getSliceEnd(i));
                  return (
                    <Fragment key={i}>
                      <CustomCarousel
                        className={styles.Carousel}
                        responsive={breakpoints}
                        showDots={showArrows(arr)}
                        partialVisible={showArrows(arr)}
                      >
                        {arr.map((r, idx) => renderReview(r, idx))}
                      </CustomCarousel>
                    </Fragment>
                  );
                })}
              </MediaQuery>
            </div>
            {showMore() && reviewCTA && renderCTA(reviewCTA)}
          </div>
        )}
      </div>
    );
  }
);
